<template>
  <vx-card title="Contenido">
    <p>Términos y condiciones del comprador</p>

    <div v-if="initProgress" style="margin-top: 1rem; width: 100%">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <div class="mt-5">
      <quill-editor v-model="content.terms"></quill-editor>
    </div>

    <!---->
    <div class="flex mt-6 flex-wrap items-center" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vx-card>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import trimString from "../mixins/trimString";

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
import {quillEditor} from 'vue-quill-editor'

export default {
  name: "Content",
  mixins: [
    trimString
  ],
  data() {
    return {
      content: {
        id: null,
        terms: ''
      },
      progress: false,
      initProgress: false
    }
  },
  components: {
    quillEditor
  },
  created() {
    try {
      this.initProgress = true
      db.collection("content").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.content.id = doc.id
          this.content.terms = doc.data().terms
        })
        this.initProgress = false
      })
    } catch (e) {
      this.initProgress = false
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.progress = true

          // Content
          let contentRef = db.collection('content').doc(this.content.id)

          //Clone to remove the id
          let cloneContent = Object.assign({}, this.content)
          delete cloneContent.id

          contentRef.update({
            ...cloneContent,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Términios y condiciones',
              text: 'La modificación fue aplicada.'
            })
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
